import styled from 'styled-components';

export const DivInform = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  div {
    p {
      text-indent: 0;
    }
  }
`;
