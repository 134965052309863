export const arrayData = [
  {
    title: 'Портал Президента Республики Беларусь',
    href: 'https://president.gov.by/ru',
    image: 'portal-prezidenta.png',
    width: '150px',
  },
  {
    title: 'Администрация Первомайского района г. Минска',
    href: 'https://pervadmin.gov.by/',
    image: 'pervoma.png',
  },
  {
    title: 'Минский областной исполнительный комитет',
    href: 'https://www.minsk-region.gov.by/',
    image: 'oblispolcom.webp',
  },
  {
    title: 'Министерство по чрезвычайным ситуациям Республики Беларусь',
    href: 'https://mchs.gov.by/',
    image: 'mchs.png',
  },
  {
    title: 'БЕЛТА',
    href: 'https://www.belta.by/ru',
    image: 'belta.webp',
    width: '150px',
  },
  {
    title: 'Беларусь сегодня',
    href: 'https://www.sb.by/',
    image: 'belarus-today.webp',
  },
  {
    title: 'Минская правда',
    href: 'https://mlyn.by/',
    image: 'minsk_truth.webp',
    width: '150px',
  },
  {
    title: 'Минск-сегодня',
    href: 'https://minsknews.by/',
    image: 'minsk-today.webp',

  },
  {
    title: 'Портал рейтинговой оценки качества оказания услуг организациями Республики Беларусь',
    href: 'http://xn----7sbgfh2alwzdhpc0c.xn--90ais/organization/33279/org-page',
    image: 'quality.png',
  },
  {
    title: 'Национальный правовой интернет-портал',
    href: 'https://pravo.by/',
    image: 'pravo.png',
  },
  {
    title: 'Минский городской исполнительный комитет',
    href: 'https://minsk.gov.by/ru/',
    image: 'comitet-ispolkom.jpg',
    width: '150px',
  },
  {
    title: 'Национальный центр законодательства и правовой информации Республики Беларусь',
    href: 'https://center.gov.by/',
    image: 'gov.jpg',
    width: '150px',
  },
];
